import React, { useState, useCallback } from 'react';
import { getLimitBalance, getApkBalance } from '@/api/balance';
import { getLocalStorage } from '@/utils/local';

const UploadModel = () => {
    const [balance, setBalance] = useState({
        url: {},
        apk: {},
    });
    const [loading, setLoading] = useState(false);
    const [balanceType, setbalanceType] = useState('');
    const userInfo = getLocalStorage('userInfo') || {};
    const pageRoles = userInfo.roles || [];
    // 过滤无权限的情况
    const getHaveRoleData = (balanceKeys) => {
        let roleArr = [
            {
                isShow: pageRoles.includes('data:multi:upload'),
                fn: getLimitBalance,
                key: 'url',
            },
            {
                isShow: pageRoles.includes('apk:view'),
                fn: getApkBalance,
                key: 'apk',
            },
        ];
        const list = [];
        const keys = [];
        roleArr.map((i) => {
            if (balanceKeys.includes(i.key) && i.isShow) {
                list.push(i.fn);
                keys.push(i.key);
            }

            return i;
        });
        return {
            list,
            keys,
        };
    };

    const getBalance = useCallback(
        async (balanceKeys = ['url', 'apk']) => {
            try {
                setLoading(true);
                const { list, keys } = getHaveRoleData(balanceKeys);
                const res = await Promise.all(list.map((i) => i({}, { isCancel: false })));
                res.map((item, index) => {
                    balance[keys[index]] = item.data;
                });
                if (keys.length === 1) {
                    setbalanceType(keys[0]);
                }
                setBalance({ ...balance });
                setLoading(false);
                return balance;
            } catch (error) {
                setLoading(false);
                return {};
            }
        },
        [balance],
    );
    return { balance, setBalance, getBalance, loading, balanceType, setbalanceType };
};

export default UploadModel;
