import axios from 'axios';
import { notification } from 'antd';
import { history } from 'umi';
import configInfo from './config';
import _ from 'lodash';
import { getLocalStorage, clearUserInfo } from '@/utils/local';
import reactDOM from 'react-dom';
import Out from '@/components/outModal';
import React from 'react';
import { clearAllCookie } from './cookie';
import { setVisitorId } from './common';
let oldCode = null;

const allPendingRequestsRecord = [];
const pending = {};

export const removeAllPendingRequest = () => {
    allPendingRequestsRecord &&
        allPendingRequestsRecord.forEach((func) => {
            func('路由跳转取消所有请求');
        });
    allPendingRequestsRecord.splice(0);
};

export const removePending = (key, isRequest = false) => {
    if (pending[key] && isRequest) {
        pending[key]('取消重复请求');
        delete pending[key];
    } else {
        Object.keys(pending).map((item) => {
            const index = item.indexOf(key);
            if (!index) {
                pending[item]('取消重复请求');
                delete pending[item];
            }
        });
    }
};
export const redirectLogin = (code) => {
    if (code && [300, 301, 302, 401].includes(code)) {
        let { is_nosugar } = getLocalStorage('userInfo') || {};

        clearAllCookie();
        clearUserInfo();

        if (is_nosugar || window.nosugar) {
            const div = document.createElement('div');
            document.body.appendChild(div);
            reactDOM.render(<Out />, div);
        } else {
            let { pathname, search } = history.location;
            if (pathname !== '/login') {
                history.push({
                    pathname: '/login',
                    query: {
                        from: pathname,
                        search,
                    },
                });
            }
        }
    }
};
/**
 * 错误提示，并处理重定向
 * @param {*Object} error 错误信息
 */
const checkStatusCode = async (error, isNotTips) => {
    // 获取下载文件流的错误提示
    if (error.type === 'application/json') {
        error = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function () {
                const result = JSON.parse(reader.result);
                resolve(result);
            };
            reader.readAsText(error);
            return;
        });
    }

    let { code, message } = error;
    if (!code) code = 700;
    if (oldCode === code) return;
    if (code === 'ECONNABORTED') {
        message = '系统处理超时，请稍后重试';
    }
    notification.destroy();
    !isNotTips &&
        notification[configInfo[code]?.i]({
            message: configInfo[code]?.m,
            description: message || configInfo[code]?.d,
            duration: 2,
            top: 80,
        });
    // 重定向
    redirectLogin(code);

    oldCode = code;
    setTimeout(() => {
        oldCode = null;
    }, 1000);
};
const instance = axios.create({
    baseURL: process.env.apiUrl,
    withCredentials: true,
    isCancel: true,
    // timeout: 60000,
});

instance.interceptors.request.use(
    async (config) => {
        let AUTH_TOKEN = getLocalStorage('token') || '';
        if (AUTH_TOKEN) {
            config.headers['Authorization'] = 'Bearer ' + AUTH_TOKEN;
        }
        await setVisitorId();
        config.headers['ime'] = window.ime;

        // 存储唯一标识
        let obj = config.params || {};
        if (config.method === 'post') obj = config.data;
        let reqData = `${config.url}${config.method}${JSON.stringify(obj)}`;

        config.isCancel && removePending(reqData, true);

        config.cancelToken = new axios.CancelToken((cancel) => {
            pending[reqData] = cancel;
            allPendingRequestsRecord.push(cancel);
        });

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (res) => {
        let { data, status, config } = res;
        if (config.url === '/api/login') return data;
        // 下载blob文件流
        if (status === 200 && data.type !== 'application/json' && config.responseType === 'blob') {
            return res;
        }
        //  adopt参数：例如获取截图，只有流没有返回code码，则可直接通过
        if ((status === 200 && data.code === 200) || config.adopt) return data;

        // 不需要错误提示（可用于单独处理错误提示）
        const isNotTips = config.isNotTips || false;

        checkStatusCode(data, isNotTips);

        return Promise.reject(data);
    },
    (error) => {
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }
        if (error.message === 'Network Error') {
            error.code = 800;
        }
        checkStatusCode(error);
        return Promise.reject(error);
    },
);

export default instance;
