// @ts-nocheck
import React from 'react';
import initialState from '/builds/Nosugar-Backend-Applications/dagou/frontend/src/.umi-production/plugin-initial-state/models/initialState';
import model0 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/controlCenter/components/Query/components/CommonProblem/models/useCommonProblem";
import model1 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/eyes/components/searchInput/models/inputModel";
import model2 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/eyes/models/uploadModel";
import model3 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/fingerprint/components/main/test/models/testModel";
import model4 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/graph/conponents/clusterSearch/models/useCluster";
import model5 from "/builds/Nosugar-Backend-Applications/dagou/frontend/src/pages/graph/conponents/search/models/searchData";
// @ts-ignore
import Dispatcher from './helpers/dispatcher';
// @ts-ignore
import Executor from './helpers/executor';
// @ts-ignore
import { UmiContext } from './helpers/constant';

export const models = { '@@initialState': initialState, 'controlCenter.components.Query.components.CommonProblem.useCommonProblem': model0, 'eyes.components.searchInput.inputModel': model1, 'eyes.uploadModel': model2, 'fingerprint.components.main.test.testModel': model3, 'graph.conponents.clusterSearch.useCluster': model4, 'graph.conponents.search.searchData': model5 };

export type Model<T extends keyof typeof models> = {
  [key in keyof typeof models]: ReturnType<typeof models[T]>;
};

export type Models<T extends keyof typeof models> = Model<T>[T]

const dispatcher = new Dispatcher!();
const Exe = Executor!;

export default ({ children }: { children: React.ReactNode }) => {

  return (
    <UmiContext.Provider value={dispatcher}>
      {
        Object.entries(models).map(pair => (
          <Exe key={pair[0]} namespace={pair[0]} hook={pair[1] as any} onUpdate={(val: any) => {
            const [ns] = pair as [keyof typeof models, any];
            dispatcher.data[ns] = val;
            dispatcher.update(ns);
          }} />
        ))
      }
      {children}
    </UmiContext.Provider>
  )
}
